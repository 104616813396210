import Vue from 'vue'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
const VUE_APP_BASE_API = `http://127.0.0.1:8888/api/private/v1/`
const instance = axios.create({
  baseURL: VUE_APP_BASE_API,
  timeout: 5000,
})
// 添加请求拦截器
instance.interceptors.request.use((config) => {
  NProgress.start()
  config.headers['Authorization'] = window.sessionStorage.getItem('token')
  return config
})
// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  NProgress.done()
  return response
})
Vue.prototype.$http = instance
