import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dataroom',
  },
  {
    path: '/dataroom',
    component: () => import('../views/bigScreen/bigScreen.vue'),
  },
  {
    path: '/dashBoard',
    component: () => import('../views/dashBoard/dashBoard.vue'),
  },
  {
    path: '/introduce',
    component: () => import('../views/introduce/index.vue'),
  },
 
 
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
})
export default router
