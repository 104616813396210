import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//第三方插件
import './plugin/element.js'
import './api/api.js'
import './assets/iconFont/iconfont.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
